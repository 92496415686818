




















































































































import { Component, Vue } from "vue-property-decorator";
import { getCurrentDoctorInfo, homeCensus } from "@/apis";
import * as echarts from "echarts";
import * as isLiu from "@/utils/judgeEnv";

@Component({
  name: "Home",
})
export default class Home extends Vue {
  onlineConsultationList: Array<any> = []; //在线问诊图表数据
  remoteControlList: Array<any> = []; // 远程程控图表数据
  //主页数据
  detailData = {
    inquiry_approve: 0, // 在线问诊-待审核
    inquiry_wait: 0, // 在线问诊-待接诊
    inquiry_ongoing: 0, //在线问诊-接诊中
    inquiry_total: 0, // 在线问诊-累计人次
    appointment_approve: 0, // 远程程控-待审核
    appointment_wait: 0, // 远程程控-待开始（待程控）
    appointment_today: 0, // 远程程控-今日程控
    appointment_total: 0, // 远程程控-累计人次
    inquiry_mounthly: [], // 在线问诊每月统计
    appointment_mounthly: [], // 远程程控每月统计
  };
  //用户姓名
  userName = "";
  //当前时间信息
  date: any = {
    year: "",
    month: "",
    date: "",
    day: "",
  };
  //在线问诊-图表实例
  onlineConsultationChart: any = null;
  //远程程控-图表实例
  remoteControlChart: any = null;
  //更新图表
  updateChart() {
    this.onlineConsultationChart.resize();
    this.remoteControlChart.resize();
  }

  created() {
    if (isLiu.judgeEnv()) {
      this.$router.push("/home_liu");
    }
  }
  async mounted() {
    const self = this;
    window.addEventListener("resize", self.updateChart);
    //已登录用户信息获取
    this.userName = localStorage.getItem("userName") as string;
    //初始化当前日期信息
    this.initDate();
    await this.getHomeDetail();
    this.initOnlineChart();
    this.initControlChart();
  }
  //初始化当前时间
  initDate() {
    const date = new Date();
    this.date.year = date.getFullYear();
    this.date.month = ("0" + (date.getMonth() + 1)).slice(-2);
    this.date.date = ("0" + date.getDate()).slice(-2);
    this.date.day = date.getDay();
    const day = ["日", "一", "二", "三", "四", "五", "六"];
    this.date.day = day[this.date.day];
  }
  beforeDestroy() {
    const self = this;
    window.removeEventListener("resize", self.updateChart);
  }
  //初始化图表数据
  initChartData(arr: any, months: number) {
    const length = arr.length;
    const result: Array<any> = [];
    if (length < 6) {
      const date = new Date();
      const year = Number(date.getFullYear());
      const month = Number(date.getMonth()) + 1;
      let countM = month + 1;
      for (let i = 0; i < months; i++) {
        countM--;
        const transMon = countM < 10 ? "0" + countM : countM;
        if (countM > 0) {
          result.push({
            amount: 0,
            date: year + "-" + transMon,
          });
        } else {
          result.push({
            amount: 0,
            date: year - 1 + "-" + (12 + countM),
          });
        }
      }
      result.reverse();
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].date === result[i].date) {
            result[i].amount = arr[j].amount;
          }
        }
      }
      return result;
    }
    return arr;
  }
  //获取当前登陆的医生首页统计信息
  getHomeDetail() {
    return new Promise((resolve) => {
      homeCensus().then((res) => {
        if (res.success) {
          this.detailData = res.data;
          this.onlineConsultationList = this.initChartData(
            res.data.inquiry_mounthly,
            6
          );
          this.remoteControlList = this.initChartData(
            res.data.appointment_mounthly,
            6
          );
          resolve();
        }
      });
    });
  }
  //初始化在线问诊图表
  initOnlineChart() {
    const chartDom = document.getElementById("onLine");
    const myChart = echarts.init(chartDom);
    const years: any = [];
    const amounts: any = [];
    this.onlineConsultationList.forEach((item: any) => {
      years.push(item.date);
      amounts.push(item.amount);
    });
    const option = {
      xAxis: {
        type: "category",
        data: years, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        boundaryGap: false,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#EBEEF5",
            width: 1, //这里是为了突出显示加上的
          },
        },
        axisLabel: {
          show: true,
          margin: 30,
          interval: 0,
          rotate: 0,
          textStyle: {
            color: "#303133",
          },
        },
      },
      yAxis: {
        type: "value",
      },
      tooltip: {
        trigger: "axis",
      },
      grid: {
        show: false,
        left: 30,
        right: 25,
        top: 40,
        height: 300,
      },
      series: [
        {
          name: "在线问诊人数:",
          smooth: true,
          data: amounts,
          // data:[150, 230, 224, 218, 135, 147, 260],
          type: "line",
          areaStyle: {},
          itemStyle: {
            normal: {
              color: "rgba(255, 152, 0, 0.1400)",
              lineStyle: {
                color: "#FF9800",
              },
            },
          },
        },
      ],
    };
    option && myChart.setOption(option);
    this.onlineConsultationChart = myChart;
  }
  //初始化远程程控图表
  initControlChart() {
    const chartDom = document.getElementById("remote");
    const myChart = echarts.init(chartDom);
    const years: any = [];
    const amounts: any = [];
    this.remoteControlList.forEach((item: any) => {
      years.push(item.date);
      amounts.push(item.amount);
    });
    const option = {
      xAxis: {
        type: "category",
        data: years, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //
        boundaryGap: false,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#EBEEF5",
            width: 1, //这里是为了突出显示加上的
          },
        },
        axisLabel: {
          show: true,
          margin: 30,
          interval: 0,
          rotate: 0,
          textStyle: {
            color: "#303133",
          },
        },
      },
      yAxis: {
        type: "value",
      },
      grid: {
        show: false,
        left: 30,
        right: 25,
        top: 40,
        height: 300,
      },
      tooltip: {
        trigger: "axis",
      },
      series: [
        {
          name: "远程程控数量:",
          smooth: true,
          data: amounts,
          // data: [150, 230, 224, 218, 135, 147, 260],
          type: "line",
          areaStyle: {},
          itemStyle: {
            normal: {
              color: "rgba(48, 114, 246, 0.1400)",
              lineStyle: {
                color: "#3072F6",
              },
            },
          },
        },
      ],
    };
    option && myChart.setOption(option);
    this.remoteControlChart = myChart;
  }
}
